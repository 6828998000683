<template>
  <div style="padding: 40px">
    <div style="display: flex; gap: 10px">
      <div style="display: flex; width: 100px; height: 100px">
        <el-button class="btn-cont" @click="toNianchuyusuanhesuan">年初预算核算</el-button>
      </div>
      <div style="display: flex; width: 100px; height: 100px">
        <el-button class="btn-cont" @click="toNianchuyusuancaizhengyunluru">年初预算财政云录入</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: "",
    };
  },

  methods: {
    toNianchuyusuancaizhengyunluru() {
      this.$router.push({name:"nianchuyusuancaizhengyunluru"})
    },
    toNianchuyusuanhesuan(){
      this.$router.push({name:"nianchuyusuanhesuan"})
    }
  },
};
</script>

<style>
.btn-cont {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden!important;
  text-wrap: wrap!important;
}
</style>