<template>
  <div style="display: flex; justify-content: center">
    <div style="min-width: 100px"></div>
    <div
      style="
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        /* width: 600px; */
      "
    >
      <div style="margin-top: 30px; font-size: 30px">预算核算</div>
      <el-form
        ref="formRef"
        style="width: 100%"
        class="inline-form"
        label-width="120px"
        label-position="left"
      >
        <el-form-item label="工资来源">
          <el-select v-model="gongzilaiyuan" placeholder="请选择">
            <el-option label="财政云导出的数据" value="财政云导出的数据" />
            <el-option label="模板数据导入" value="模板数据导入" />
          </el-select>
        </el-form-item>
        <el-form-item label=" " v-if="gongzilaiyuan == '财政云导出的数据'">
          <el-text>
            导出途径：人员台账 → 人员变动信息办理 → 查询统计 → 单位工资明细
            →导出
          </el-text>
        </el-form-item>
        <el-form-item>
          <el-upload
            :show-file-list="true"
            :auto-upload="false"
            :file-list="filelist"
            :on-change="handleChange"
            ref="upload"
            :limit="1"
            accept=".xlsx,.xls"
            v-if="gongzilaiyuan == '财政云导出的数据'"
          >
            <template #trigger>
              <el-button type="success">上传财政云导出的工资数据</el-button>
            </template>
          </el-upload>
          <el-button
            style="margin-left: 20px"
            type="primary"
            v-if="gongzilaiyuan == '模板数据导入'"
            >下载工资数据模板</el-button
          >
          <el-button
            style="margin-left: 20px"
            type="success"
            v-if="gongzilaiyuan == '模板数据导入'"
            >上传工资数据模板</el-button
          >
        </el-form-item>
      </el-form>
      <el-form
        style="width: 100%"
        class="inline-form"
        label-width="120px"
        label-position="left"
      >
        <el-form-item label="遗属情况" prop="name">
          <el-form ref="formRef" :model="form" label-width="80">
            <el-form-item label="城市户口"
              ><el-input
                v-model="遗属城市户口"
                placeholder="请输入"
                class="w125"
                type="number"
              />人，标准350元/月/人</el-form-item
            >
            <el-form-item label="农村户口"
              ><el-input
                v-model="遗属农村户口"
                placeholder="请输入"
                class="w125"
                type="number"
              />人，标准300元/月/人</el-form-item
            >
            <el-form-item label="离休人员"
              ><el-input
                v-model="遗属离休人员"
                placeholder="请输入"
                class="w125"
                type="number"
              />人，标准1300元/月/人</el-form-item
            >
          </el-form>
        </el-form-item>
        <el-form-item label="独生子女保健费">
          <el-input
            v-model="独生子女保健费"
            placeholder="请输入"
            class="w205"
            type="number"
          />人，标准15元/月/人
        </el-form-item>
        <el-form-item label="退休人数">
          <el-input
            v-model="退休人数"
            placeholder="请输入"
            class="w205"
            type="number"
          />人，涉及大病，5元/月/人
        </el-form-item>
        <el-form-item label="精退人数">
          <el-input
            v-model="精退人数"
            placeholder="请输入"
            class="w205"
            type="number"
          />人，471元/月/人
        </el-form-item>
        <el-form-item label="其他预算">
          <div
            style="
              width: 600px;
              display: flex;
              align-items: center;
              flex-direction: column;
            "
          >
            <el-table
              :data="其他预算"
              :style="{ width: '100%' }"
              border
              class="my-table"
            >
              <el-table-column prop="project" label="预算项目" min-width="180">
                <template #default="scope">
                  <el-input v-model="scope.row.project"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="formula"
                label="金额（支持公式，别加等号）"
                width="220"
              >
                <template #default="scope">
                  <el-input v-model="scope.row.formula"></el-input>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="warning" style="margin-top: 10px" @click="addItem"
              >添加其他预算项</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button style="width: 340px" type="primary" @click="genFile"
            >生成数据</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div style="min-width: 100px"></div>
  </div>
</template>

<script>
import * as XLSX from "xlsx-js-style";
export default {
  data() {
    return {
      filelist: [],
      gongzilaiyuan: "",
      output: {},
      遗属城市户口: 0,
      遗属农村户口: 0,
      遗属离休人员: 0,
      独生子女保健费: 0,
      退休人数: 0,
      精退人数: 0,
      其他预算: [],
      json1Data: [],
    };
  },
  methods: {
    handleChange(file, files) {
      this.$data.filelist = files;
    },
    addItem() {
      var a = {
        project: "",
        formula: "",
      };
      this.$data.其他预算.push(a);
    },
    改革性补贴获取改革性补贴1(补贴) {
      var map = [
        ["正县级领导职务", 530, 675],
        ["调研员", 250, 645],
        ["副县级领导职务", 520, 595],
        ["副调研员", 360, 580],
        ["正科级领导职务", 540, 555],
        ["主任科员", 440, 530],
        ["副科级领导职务", 570, 500],
        ["副主任科员", 480, 475],
        ["科员", 560, 440],
        ["办事员", 540, 320],
      ];

      for (var i = 0; i < map.length; i++) {
        if (map[i][1] + map[i][2] == 补贴) {
          return map[i][1];
        }
      }
      return 0;
    },

    /**
     *
     * @param 补贴
     * @returns 0科级及以下，1处级及以上
     */

    从改革性补贴获取职务(补贴) {
      var map = [
        ["正县级领导职务", 530, 675],
        ["调研员", 250, 645],
        ["副县级领导职务", 520, 595],
        ["副调研员", 360, 580],
        ["正科级领导职务", 540, 555],
        ["主任科员", 440, 530],
        ["副科级领导职务", 570, 500],
        ["副主任科员", 480, 475],
        ["科员", 560, 440],
        ["办事员", 540, 320],
      ];

      for (var i = 0; i < map.length; i++) {
        if (map[i][1] + map[i][2] == 补贴) {
          return i <= 3 ? 1 : 0;
        }
      }
    },

    getValue(v) {
      return v ? v : 0;
    },
    addCell(ws, position, v) {
      if (ws[position] == null) ws[position] = {};
      ws[position].v = v;
    },
    genFile() {
      if (this.$data.filelist.length == 0) {
        return;
      }
      const f = this.$data.filelist[0].raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.$data.jsonData = XLSX.utils.sheet_to_json(worksheet);

        const sum = this.$data.jsonData[this.$data.jsonData.length - 1];
        const base = this.$data.jsonData[0];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([]);
        const merge = [
          { s: { r: 0, c: 0 }, e: { r: 3, c: 0 } },
          { s: { r: 0, c: 1 }, e: { r: 3, c: 1 } },
          { s: { r: 0, c: 2 }, e: { r: 3, c: 2 } },
          { s: { r: 0, c: 3 }, e: { r: 0, c: 9 } },
          { s: { r: 0, c: 10 }, e: { r: 0, c: 13 } },
          { s: { r: 0, c: 14 }, e: { r: 0, c: 24 } },
          { s: { r: 0, c: 25 }, e: { r: 0, c: 32 } },
          { s: { r: 0, c: 33 }, e: { r: 3, c: 33 } },
          { s: { r: 0, c: 34 }, e: { r: 1, c: 45 } },

          { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } },
          { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } },
          { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } },
          { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } },
          { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } },
          { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
          { s: { r: 1, c: 9 }, e: { r: 3, c: 9 } },
          { s: { r: 1, c: 10 }, e: { r: 3, c: 10 } },

          { s: { r: 1, c: 11 }, e: { r: 2, c: 12 } },
          { s: { r: 1, c: 13 }, e: { r: 3, c: 13 } },
          { s: { r: 1, c: 14 }, e: { r: 3, c: 14 } },

          { s: { r: 1, c: 15 }, e: { r: 2, c: 17 } },
          { s: { r: 1, c: 18 }, e: { r: 2, c: 20 } },

          { s: { r: 1, c: 21 }, e: { r: 3, c: 21 } },
          { s: { r: 1, c: 22 }, e: { r: 3, c: 22 } },
          { s: { r: 1, c: 23 }, e: { r: 3, c: 23 } },
          { s: { r: 1, c: 24 }, e: { r: 3, c: 24 } },
          { s: { r: 1, c: 25 }, e: { r: 3, c: 25 } },
          { s: { r: 1, c: 26 }, e: { r: 3, c: 26 } },
          { s: { r: 1, c: 27 }, e: { r: 3, c: 27 } },
          { s: { r: 1, c: 28 }, e: { r: 3, c: 28 } },
          { s: { r: 1, c: 29 }, e: { r: 3, c: 29 } },
          { s: { r: 1, c: 30 }, e: { r: 3, c: 30 } },
          { s: { r: 1, c: 31 }, e: { r: 3, c: 31 } },
          { s: { r: 1, c: 32 }, e: { r: 3, c: 32 } },

          { s: { r: 2, c: 34 }, e: { r: 3, c: 34 } },
          { s: { r: 2, c: 35 }, e: { r: 3, c: 35 } },
          { s: { r: 2, c: 36 }, e: { r: 3, c: 36 } },
          { s: { r: 2, c: 37 }, e: { r: 3, c: 37 } },
          { s: { r: 2, c: 38 }, e: { r: 3, c: 38 } },
          { s: { r: 2, c: 39 }, e: { r: 3, c: 39 } },
          { s: { r: 2, c: 40 }, e: { r: 3, c: 40 } },
          { s: { r: 2, c: 41 }, e: { r: 3, c: 41 } },
          { s: { r: 2, c: 42 }, e: { r: 3, c: 42 } },
          { s: { r: 2, c: 43 }, e: { r: 3, c: 43 } },
          { s: { r: 2, c: 44 }, e: { r: 3, c: 44 } },
          { s: { r: 2, c: 45 }, e: { r: 3, c: 45 } },
        ];
        ws["!merges"] = merge;
        ws["!ref"] = "A1:AT5";

        for (var i = 0; i < 6; i++) {
          for (var j = 0; j < 46; j++) {
            var addr = XLSX.utils.encode_cell({ r: i, c: j });
            ws[addr] = {
              v: 0,
              s: {
                border: {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                },
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                  wrapText: true,
                },
                font: { sz: 12, name: "仿宋" },
              },
            };
          }
        }

        var zaizhi = 0;
        var xieli = 0;
        var ligang = 0;
        var pingyong = 0;
        var lixiu = 0;
        var jingtui = 0;
        var yishu = 0;
        var youfu = 0;
        var qita = 0;

        sum["改革性补贴1"] = 0;
        var chuji = 0;
        var keji = 0;
        for (var i = 0; i < this.$data.jsonData.length - 1; i++) {
          if (this.$data.jsonData[i]["工资类型"] == "退休") {
            continue;
          }
          if (this.$data.jsonData[i]["内设机构"] == "在编") {
            zaizhi++;
          } else if (this.$data.jsonData[i]["内设机构"] == "区聘") {
            pingyong++;
          } else if (this.$data.jsonData[i]["内设机构"] == "协离") {
            xieli++;
          }
          sum["改革性补贴1"] += this.改革性补贴获取改革性补贴1(
            this.$data.jsonData[i]["改革性补贴"]
          );
          if (this.$data.jsonData[i]["工资类型"] == "行政") {
            if (
              this.从改革性补贴获取职务(this.$data.jsonData[i]["改革性补贴"]) ==
              0
            ) {
              keji++;
            } else if (
              this.从改革性补贴获取职务(this.$data.jsonData[i]["改革性补贴"]) ==
              1
            ) {
              chuji++;
            }
          } else {
            keji++;
          }
        }

        this.addCell(ws, "A1", "单位编码");
        this.addCell(ws, "B1", "单位名称");
        this.addCell(ws, "C1", "总计");
        this.addCell(
          ws,
          "D1",
          "一、在职人员工资奖金津补贴（该数据来源于人员台账导出工资表）"
        );
        this.addCell(ws, "D2", "工资奖金津补贴合计");
        this.addCell(ws, "E2", "月计");
        this.addCell(ws, "F2", "基本工资");
        this.addCell(ws, "G2", "津贴补贴");
        this.addCell(ws, "H2", "住房补贴（2210201）");
        this.addCell(ws, "I2", "事业绩效工资");
        this.addCell(ws, "J2", "基础绩效奖金");

        this.addCell(ws, "K1", "二、对个人和家庭补助（未参保离退休费50905）");
        this.addCell(ws, "K2", "离退休工资合计");
        this.addCell(ws, "L2", "离退休人员工资（行政2080501，事业2080502）");
        this.addCell(ws, "L4", "月计");
        this.addCell(ws, "M4", "年计");
        this.addCell(ws, "N2", "离休干部生活补贴");
        this.addCell(ws, "O1", "三、丧抚及其他津补贴");
        this.addCell(ws, "O2", "丧抚及其他津补贴合计");
        this.addCell(ws, "P2", "抚恤及丧葬费");
        this.addCell(ws, "P4", "丧抚小计");
        this.addCell(ws, "Q4", "伤残抚恤（2080802）");
        this.addCell(ws, "R4", "死亡抚恤（2080801）");
        this.addCell(ws, "S1", "取暖降温福利费（单位主科目）");
        this.addCell(ws, "S2", "取暖降温福利费（单位主科目）");
        this.addCell(ws, "S4", "小计");
        this.addCell(ws, "T4", "取暖福利");
        this.addCell(ws, "U4", "降温费");
        this.addCell(ws, "V2", "精退（单位主科目）");
        this.addCell(ws, "W2", "独生子女保健费（单位主科目）");
        this.addCell(ws, "X2", "遗属（单位主科目）");
        this.addCell(ws, "Y2", "其他");
        this.addCell(ws, "Z1", "四、社保单位缴费部分");
        this.addCell(ws, "Z2", "社保合计");
        this.addCell(ws, "AA2", "养老基数");
        this.addCell(ws, "AB2", "养老（2080505）");
        this.addCell(ws, "AC2", "医疗6%");
        this.addCell(ws, "AD2", "大病1%");
        this.addCell(ws, "AE2", "生育0.5%");
        this.addCell(ws, "AF2", "三项医保合计（行政2101101，事业2101102）");
        this.addCell(ws, "AG2", "工伤0.2%（单位主科目）");

        this.addCell(ws, "AH1", "五、人员编制数");
        this.addCell(ws, "AI1", "六、财政供养人员情况");

        this.addCell(ws, "AI3", "合计");
        this.addCell(ws, "AJ3", "在职");
        this.addCell(ws, "AK3", "协理");
        this.addCell(ws, "AL3", "离岗");
        this.addCell(ws, "AM3", "聘用");
        this.addCell(ws, "AN3", "离休");
        this.addCell(ws, "AO3", "退休");
        this.addCell(ws, "AP3", "精退");
        this.addCell(ws, "AQ3", "遗属");
        this.addCell(ws, "AR3", "优抚");
        this.addCell(ws, "AS3", "其他");
        this.addCell(ws, "AT3", "备注");

        ws["!rows"] = [
          { hpx: 36 }, // 第一行行高设置为20像素
          { hpx: 32 }, // 第二行行高设置为30像素
          { hpx: 32 }, // 第三行行高设置为25像素
          { hpx: 36 }, // 第四行行高设置为35像素
        ];

        ws["!cols"] = [
          { wch: 11 }, // A
          { wch: 30 }, // B
          { wch: 18 }, // C
          { wch: 21 }, // D
          { wch: 14 }, // E
          { wch: 15 }, // F
          { wch: 14 }, // G
          { wch: 15 }, // H
          { wch: 15 }, // I
          { wch: 8 }, // J
          { wch: 8 }, // K
          { wch: 8 }, // L
          { wch: 8 }, // M
          { wch: 8 }, // N
          { wch: 16 }, // O
          { wch: 13 }, // P
          { wch: 13 }, // Q
          { wch: 13 }, // R
          { wch: 13 }, // S
          { wch: 13 }, // T
          { wch: 13 }, // U
          { wch: 15 }, // V
          { wch: 15 }, // W
          { wch: 15 }, // X
          { wch: 15 }, // Y
          { wch: 16 }, // Z
          { wch: 16 }, // AA
          { wch: 16 }, // AB
          { wch: 16 }, // AC
          { wch: 16 }, // AD
          { wch: 16 }, // AE
          { wch: 16 }, // AF

          { wch: 16 }, // AG
          { wch: 8 }, // AH
          { wch: 6 }, // AI
          { wch: 6 }, // AJ
          { wch: 6 }, // AK
          { wch: 6 }, // AL
          { wch: 6 }, // AM
          { wch: 6 }, // AN
          { wch: 6 }, // AO
          { wch: 6 }, // AP
          { wch: 6 }, // AQ
          { wch: 6 }, // AR
          { wch: 6 }, // AS
          { wch: 30 }, // AT
        ];

        ws["A5"].v = base["单位"].substr(0, 6);
        ws["B5"].v = base["单位"].substr(7);
        ws["C5"].f = "D5+K5+O5+Z5";
        ws["D5"].f = "E5*12";
        ws["E5"].f = "F5+G5+H5+I5+J5";

        var deptType = "事业";
        if (base["单位"].substr(0, 6).substr(3) == "001") {
          deptType = "行政";
        }

        ws["F5"].f =
          "" +
          sum["职务（岗位）工资"] +
          "+" +
          sum["级别（技术等级、薪级）工资"] +
          "+" +
          this.getValue(sum["教护10%"]) +
          "+" +
          this.getValue(sum["保留补贴"]);

        var g5v =
          "" +
          this.getValue(sum["警衔津贴"]) +
          "+" +
          this.getValue(sum["生活补贴"]) +
          "+" +
          this.getValue(sum["工作津贴"]) +
          "+" +
          this.getValue(sum["特殊岗位津贴"]) +
          "+" +
          this.getValue(sum["乡镇补贴"]) +
          "+" +
          this.getValue(sum["教护龄津贴"]) +
          "+" +
          this.getValue(sum["养老补贴"]) +
          "+" +
          this.getValue(sum["其他"]) +
          "+" +
          this.getValue(sum["改革性补贴"]);

        if (deptType == "行政") {
          g5v +=
            "+" +
            this.getValue(sum["基础性绩效"]) +
            "+" +
            this.getValue(sum["奖励性绩效"]);
        }

        ws["G5"].f = g5v;

        ws["H5"].f = "" + this.getValue(sum["住房公积金补贴"]);

        if (deptType == "行政") {
        } else {
          ws["I5"].f =
            "" +
            this.getValue(sum["基础性绩效"]) +
            "+" +
            this.getValue(sum["奖励性绩效"]);
        }

        ws["J5"].f = "" + this.getValue(sum["基础绩效奖"]);

        ws["K5"].f = "M5+N5";

        ws["L5"].f = "" + this.getValue(sum["离休费"]);

        ws["M5"].f = "12*L5";

        ws["N5"].f =
          "" +
          this.getValue(sum["离休生活补贴"]) +
          "" +
          this.getValue(sum["护理费"]);

        ws["O5"].f = "P5+S5+V5+W5+X5+Y5";

        ws["P5"].f = "Q5+R5";

        ws["S5"].f = "T5+U5";

        ws["T5"].f = "2590*" + keji + "+3150*" + chuji;
        ws["U5"].f = "(AJ5+AK5+AL5+AM5)*620";

        ws["V5"].f = "471*12*" + this.$data.精退人数;

        ws["W5"].f = "15*12*" + this.$data.独生子女保健费;

        ws["X5"].f =
          "12*(350*" +
          this.$data.遗属城市户口 +
          "+300*" +
          this.$data.遗属农村户口 +
          "+1300*" +
          this.$data.遗属离休人员 +
          ")";

        var y5f = 0;
        var y5c = "";
        for (var i = 0; i < this.$data.其他预算.length; i++) {
          if (i == 0) {
            y5f = "(" + this.$data.其他预算[i].formula + ")";
          } else {
            y5f += "+(" + this.$data.其他预算[i].formula + ")";
          }
          y5c +=
            "【" +
            this.$data.其他预算[i].project +
            "：(" +
            this.$data.其他预算[i].formula +
            ")】";
        }

        ws["Y5"].f = y5f;
        ws["Y5"].c = [{ t: y5c }];
        ws["Y5"].c.hidden = true;

        ws["Z5"].f = "AB5+AF5+AG5";
        ws["Z5"].z = "0.00";

        ws["AB5"].f = "AA5*0.16";
        ws["AB5"].z = "0.00";

        ws["AC5"].f = "D5*0.06";
        ws["AC5"].z = "0.00";

        ws["AD5"].f = "(AJ5+AK5+AL5+AM5+AN5+AO5+AP5)*60";

        ws["AE5"].f = "D5*0.005";
        ws["AE5"].z = "0.00";

        ws["AF5"].f = "AC5+AD5+AE5";
        ws["AF5"].z = "0.00";

        ws["AG5"].f = "D5*0.002";
        ws["AG5"].z = "0.00";

        ws["AI5"].f = "AJ5+AK5+AL5+AM5";

        ws["AJ5"].f = "+" + zaizhi;
        ws["AK5"].f = "+" + xieli;
        ws["AL5"].f = "+" + ligang;
        ws["AM5"].f = "+" + pingyong;
        ws["AN5"].f = "+" + lixiu;
        ws["AO5"].f = "+" + this.$data.退休人数;
        ws["AP5"].f = "+" + jingtui;
        ws["AQ5"].f = "+" + yishu;
        ws["AR5"].f = "+" + youfu;
        ws["AS5"].f = "+" + qita;

        //委托代扣个人养老保险、职业年金申报表
        const ws1 = XLSX.utils.aoa_to_sheet([]);
        ws1["!ref"] =
          "A1:" +
          XLSX.utils.encode_cell({
            c: 21,
            r: this.$data.jsonData.length - 1 + 5,
          });
        const merge1 = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 20 } },
          { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },

          { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } },
          { s: { r: 2, c: 1 }, e: { r: 3, c: 1 } },
          { s: { r: 2, c: 2 }, e: { r: 3, c: 2 } },
          { s: { r: 2, c: 3 }, e: { r: 3, c: 3 } },
          { s: { r: 2, c: 4 }, e: { r: 3, c: 4 } },
          { s: { r: 2, c: 5 }, e: { r: 3, c: 5 } },
          { s: { r: 2, c: 6 }, e: { r: 3, c: 6 } },
          { s: { r: 2, c: 7 }, e: { r: 3, c: 7 } },
          { s: { r: 2, c: 8 }, e: { r: 3, c: 8 } },
          { s: { r: 2, c: 9 }, e: { r: 3, c: 9 } },
          { s: { r: 2, c: 10 }, e: { r: 3, c: 10 } },
          { s: { r: 2, c: 11 }, e: { r: 3, c: 11 } },
          { s: { r: 2, c: 12 }, e: { r: 3, c: 12 } },
          { s: { r: 2, c: 13 }, e: { r: 3, c: 13 } },
          { s: { r: 2, c: 14 }, e: { r: 3, c: 14 } },
          { s: { r: 2, c: 15 }, e: { r: 3, c: 15 } },
          { s: { r: 2, c: 16 }, e: { r: 3, c: 16 } },
          { s: { r: 2, c: 17 }, e: { r: 3, c: 17 } },
          { s: { r: 2, c: 18 }, e: { r: 2, c: 20 } },
        ];
        ws1["!merges"] = merge1;
        for (var i = 2; i < this.$data.jsonData.length - 1 + 5; i++) {
          for (var j = 0; j < 21; j++) {
            var addr = XLSX.utils.encode_cell({ r: i, c: j });
            ws1[addr] = {
              v: 0,
              z: j > 14 ? "0.00" : null,
              t: j > 4 ? "n" : "s",
              s: {
                border: {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                },
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                  wrapText: true,
                },
                font: { sz: 11, name: "宋体" },
              },
            };
          }
        }

        ws1["A1"] = {
          v: "委托代扣个人养老保险、职业年金申报表",
          s: {
            font: { sz: 20, name: "方正小标宋简体" },
            alignment: {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            },
          },
        };
        ws1["A2"] = {
          v: "填报单位：" + base["单位"].substr(7),
          s: {
            font: { sz: 12, name: "宋体" },
            alignment: {
              horizontal: "left",
              vertical: "center",
              wrapText: true,
            },
          },
        };
        ws1["U2"] = {
          v: "单位：元",
          s: {
            font: { sz: 12, name: "宋体" },
            alignment: {
              horizontal: "left",
              vertical: "center",
              wrapText: true,
            },
          },
        };

        ws1["A3"].v = "序号";
        ws1["B3"].v = "单位编码";
        ws1["C3"].v = "单位";
        ws1["D3"].v = "姓名";
        ws1["E3"].v = "身份证号";
        ws1["F3"].v = "岗位（职务）工资";
        ws1["G3"].v = "薪级（级别）工资";
        ws1["H3"].v = "提高10%";
        ws1["I3"].v = "艰苦边远津贴";
        ws1["J3"].v = "改革性补贴(机关）";
        ws1["K3"].v = "绩效（生活补贴、工作津贴）工资";
        ws1["L3"].v = "保留补贴（含住房补贴）";
        ws1["M3"].v = "教护（警衔海关）津贴";
        ws1["N3"].v = "特教津贴";
        ws1["O3"].v = "基础绩效奖";
        ws1["P3"].v = "年终一次性奖金（其他）";
        ws1["Q3"].v = "月平均缴费工资";
        ws1["R3"].v = "单位缴费16%";
        ws1["S3"].v = "代扣金额";
        ws1["S4"].v = "合计";
        ws1["T4"].v = "养老保险8%";
        ws1["U4"].v = "职业年金4%";

        var jumpCount = 0;
        var yanglaojishu = 0;
        for (var i = 0; i < this.$data.jsonData.length - 1; i++) {
          var rowIdx = i + 4 - jumpCount;
          if (
            this.$data.jsonData[i]["内设机构"] == "区聘" ||
            this.$data.jsonData[i]["工资类型"] == "退休"
          ) {
            jumpCount++;
            continue;
          }
          var ref = "";
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 0 }); //序号
          ws1[ref].v = i + 1 - jumpCount;
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 1 }); //单位编码
          ws1[ref].v = base["单位"].substr(0, 6);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 2 }); //单位
          ws1[ref].v = base["单位"].substr(7);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 3 }); //姓名
          ws1[ref].v = this.$data.jsonData[i]["姓名"];
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 4 }); //身份证号
          ws1[ref].v = this.$data.jsonData[i]["身份证号"];
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 5 }); //职务（岗位）工资
          ws1[ref].v = this.$data.jsonData[i]["职务（岗位）工资"];
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 6 }); //级别（技术等级、薪级）工资
          ws1[ref].v = this.$data.jsonData[i]["级别（技术等级、薪级）工资"];
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 7 }); //提高10
          ws1[ref].v = this.getValue(this.$data.jsonData[i]["提高10%"]);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 8 }); //艰苦边远津贴
          ws1[ref].v = this.getValue(this.$data.jsonData[i]["艰苦边远津贴"]);
          if (deptType == "行政") {
            ref = XLSX.utils.encode_cell({ r: rowIdx, c: 9 }); //改革性补贴(机关）
            ws1[ref].v = this.改革性补贴获取改革性补贴1(
              this.$data.jsonData[i]["改革性补贴"]
            );
          }
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 10 }); //绩效（生活补贴、工作津贴）工资
          ws1[ref].v = this.$data.jsonData[i]["津补贴合计"];
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 11 }); //保留补贴（含住房补贴）
          ws1[ref].v =
            this.$data.jsonData[i]["保留补贴"] +
            this.$data.jsonData[i]["住房公积金补贴"];

          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 12 }); //教护（警衔海关）津贴
          ws1[ref].v = this.getValue(
            this.$data.jsonData[i]["教护（警衔海关）津贴"]
          );
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 13 }); //特教津贴
          ws1[ref].v = this.getValue(this.$data.jsonData[i]["特教津贴"]);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 14 }); //基础绩效奖
          ws1[ref].v = this.$data.jsonData[i]["基础绩效奖"];
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 15 }); //年终一次性奖金（其他）
          ws1[ref].v =
            (this.$data.jsonData[i]["职务（岗位）工资"] +
              this.$data.jsonData[i]["级别（技术等级、薪级）工资"]) /
            12;
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 16 }); //月平均缴费工资
          ws1[ref].f = "F5+G5+J5+K5+L5+O5+P5".replaceAll("5", rowIdx + 1);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 17 }); //单位缴费16%
          ws1[ref].f = "0.16*Q" + (rowIdx + 1);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 18 }); //合计
          ws1[ref].f = "T5+U5".replaceAll("5", rowIdx + 1);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 19 }); //养老保险8%
          ws1[ref].f = "Q5*0.08".replaceAll("5", rowIdx + 1);
          ref = XLSX.utils.encode_cell({ r: rowIdx, c: 20 }); //职业年金4%
          ws1[ref].f = "Q5*0.04".replaceAll("5", rowIdx + 1);

          yanglaojishu +=
            this.getValue(this.$data.jsonData[i]["职务（岗位）工资"]) +
            this.getValue(
              this.$data.jsonData[i]["级别（技术等级、薪级）工资"]
            ) +
            this.改革性补贴获取改革性补贴1(
              this.$data.jsonData[i]["改革性补贴"]
            ) +
            this.$data.jsonData[i]["津补贴合计"] +
            this.$data.jsonData[i]["保留补贴"] +
            this.$data.jsonData[i]["住房公积金补贴"] +
            this.$data.jsonData[i]["基础绩效奖"] +
            (this.$data.jsonData[i]["职务（岗位）工资"] +
              this.$data.jsonData[i]["级别（技术等级、薪级）工资"]) /
              12;
        }

        var hanggao = [
          { hpx: 48 }, // 第一行行高设置为20像素
          { hpx: 24 }, // 第二行行高设置为30像素
          { hpx: 30 }, // 第三行行高设置为25像素
          { hpx: 30 }, // 第四行行高设置为35像素
        ];

        for (var i = 0; i < this.$data.jsonData.length - jumpCount; i++) {
          hanggao.push({ hpx: 21 });
        }
        ws1["!rows"] = hanggao;
        ws1["!cols"] = [
          { wch: 5 }, // A
          { wch: 9 }, // B
          { wch: 30 }, // C
          { wch: 8 }, // D
          { wch: 22 }, // E
          { wch: 9 }, // F
          { wch: 9 }, // G
          { wch: 6 }, // H
          { wch: 6 }, // I
          { wch: 8 }, // J
          { wch: 11 }, // K
          { wch: 10 }, // L
          { wch: 8 }, // M
          { wch: 6 }, // N
          { wch: 7 }, // O
          { wch: 9 }, // P
          { wch: 11 }, // Q
          { wch: 11 }, // R
          { wch: 10 }, // S
          { wch: 10 }, // T
          { wch: 10 }, // U
        ];

        for (
          var i = this.$data.jsonData.length - jumpCount + 4;
          i < this.$data.jsonData.length - 1 + 5;
          i++
        ) {
          for (var j = 0; j < 21; j++) {
            var ref = XLSX.utils.encode_cell({ c: j, r: i });
            delete ws1[ref];
          }
        }
        for (var i = 0; i < 21; i++) {
          var ref = XLSX.utils.encode_cell({
            c: i,
            r: this.$data.jsonData.length + 3 - jumpCount,
          });
          ws1[ref].v = "";
        }

        ws1["C" + (this.$data.jsonData.length + 4 - jumpCount)].v = "合计";
        ws1["Q" + (this.$data.jsonData.length + 4 - jumpCount)].f =
          "sum(Q5:Q" + (this.$data.jsonData.length + 3 - jumpCount) + ")";
        ws1["R" + (this.$data.jsonData.length + 4 - jumpCount)].f =
          "sum(R5:R" + (this.$data.jsonData.length + 3 - jumpCount) + ")";
        ws1["S" + (this.$data.jsonData.length + 4 - jumpCount)].f =
          "sum(S5:S" + (this.$data.jsonData.length + 3 - jumpCount) + ")";
        ws1["T" + (this.$data.jsonData.length + 4 - jumpCount)].f =
          "sum(T5:T" + (this.$data.jsonData.length + 3 - jumpCount) + ")";
        ws1["U" + (this.$data.jsonData.length + 4 - jumpCount)].f =
          "sum(U5:U" + (this.$data.jsonData.length + 3 - jumpCount) + ")";

        ws["AA5"].f = "12*" + yanglaojishu.toFixed(2);
        XLSX.utils.book_append_sheet(wb, ws, "预算表");
        XLSX.utils.book_append_sheet(wb, ws1, "养老保险职业年金");
        XLSX.writeFile(wb, "merged_cells.xlsx");
      };
      reader.readAsArrayBuffer(f);
    },
  },
};
</script>

<style>
.inline-form .el-input {
  --el-input-width: 220px;
}

.inline-form .el-select {
  --el-select-width: 220px;
}

.el-input.w125 {
  width: 125px;
}

.el-input.w205 {
  width: 205px;
}

.inline-form .el-table {
  --el-table-border-color: #333333;
}

.my-table .el-input {
  width: 100%;
}

.el-upload-list__item-file-name {
  width: 100%;
  text-align: left;
}

.el-form-item__content > div {
  width: 100%;
}
</style>
