import { createRouter, createWebHashHistory } from 'vue-router'
import Learn from '../views/Learn.vue'
import Home from "../views/Home.vue"
import MenuManage from '@/views/MenuManage.vue'
import Yujuesuan from "@/views/Yujuesuan.vue"
import Nianchuyusuancaizhengyunluru from "@/views/help/Nianchuyusuancaizhengyunluru.vue"
import Nianchuyusuanhesuan from '@/views/Nianchuyusuanhesuan.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/learn/:articleType',
    name: 'learn',
    component: Learn
  },
  {
    path: '/menu_manage',
    name: 'menuManage',
    component: MenuManage
  },
  {
    path: '/yujuesuan',
    name: 'yujuesuan',
    component: Yujuesuan
  },
  {
    path: '/nianchuyusuancaizhengyunluru',
    name: 'nianchuyusuancaizhengyunluru',
    component: Nianchuyusuancaizhengyunluru
  },
  {
    path: '/nianchuyusuanhesuan',
    name: 'nianchuyusuanhesuan',
    component: Nianchuyusuanhesuan
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
