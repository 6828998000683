<template>
  <div style="display: flex; width: 100%; padding-bottom: 30px">
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 220px;
      "
    >
      <div
        style="
          width: 200px;
          border-right: 1px solid #aaa;
          position: fixed;
          left: 0px;
          top: 20px;
        "
      >
        <el-button class="btn bb" @click="scrollTo('luruliucheng')"
          >第一部分：录入流程</el-button
        >
        <el-button class="btn bb" @click="scrollTo('chuangjianxiangmu')"
          >第二部分：创建项目</el-button
        >
        <el-button class="btn bb" @click="scrollTo('xiangmuxihua')"
          >第三部分：项目细化</el-button
        >
        <el-button class="btn bb" @click="scrollTo('zijinfenlei')"
          >第四部分：资金分类</el-button
        >
      </div>
    </div>
    <div style="flex: 1; padding-bottom: 100px">
      <h1 ref="luruliucheng">第一部分 录入流程</h1>
      <p>
        （1）核算年初预算上报区财政，等区财政审核后方可生效。<span
          >【点此查看如何核算年初预算】</span
        >
      </p>
      <p>（2）按照审核后的预算额度在财政云上创建项目。</p>
      <p>（3）项目创建后进行项目细化。</p>

      <h1 ref="chuangjianxiangmu">第二部分 创建项目</h1>
      <p>登录财政云后创建项目。</p>
      <p>
        创建项目入口：
        【预算管理】→【项目库管理功能菜单】→【人员类项目申报（预算单位）】→【左侧选中单位】→【新增】

        <img :src="image_add" alt="" srcset="" style="width: 100%" />
      </p>

      <hr />

      <p>弹出的的界面【项目基本信息】页面如下，其中红色方框需要填写。</p>
      <img :src="image_baseinfo" alt="" srcset="" style="width: 100%" />
      <p>
        <b style="color: red"
          >上图【项目类别】、【存续状态】、【支出项目类别】、【项目名称】的内容填写需严格按照第四部分资金分类中的要求填写。否则，审核不通过。</b
        >【申报联系人】填会计姓名，【联系电话】填会计电话，【项目概述】、【费用结构】、【测算方式】与【项目名称】一致；
      </p>
      <hr />

      <p>
        弹出的的界面【分年度支出计划】页面如下，其中红色方框需要填写财政拨款额，额度计算方式参考第四部分资金分类。
        <img :src="image_yearplan" alt="" srcset="" style="width: 100%" />
      </p>

      <hr />

      <p>
        弹出的的界面【项目附件信息】页面如下，此界面用来上传项目文件。是否需要上传项目文件以及项目文件联系主管部门获取。
        <img :src="image_extrainfo" alt="" srcset="" style="width: 100%" />
      </p>

      <hr />

      <p>
        弹出的的界面【热点设置】页面如下。<b style="color: red"
          >热点分类选择长期热点，热点编码选择保工资。</b
        >
      </p>
      <img :src="image_hot" alt="" srcset="" style="width: 100%" />

      <hr />

      <p>
        完成上述内容填写后，即可保存项目。保存后的项目一定要送审后才可以使用。
      </p>
      <img :src="image_send" alt="" srcset="" style="width: 100%" />

      <hr />

      <h1 ref="xiangmuxihua">第三部分 项目细化</h1>

      <h1 ref="zijinfenlei">第四部分 资金分类</h1>
      <p>资金分为以下几类：</p>
      <text>（01）基本工资；</text>
      <text>（02）津贴补贴；</text>
      <text>（03）事业绩效工资；</text>
      <text>（04）基础绩效奖金；</text>
      <text>（05）住房补贴；</text>
      <text>（06）取暖费；</text>
      <text>（07）降温费；</text>
      <text>（08）养老保险；</text>
      <text>（09）医疗保险；</text>
      <text>（10）大病保险；</text>
      <text>（11）生育保险；</text>
      <text>（12）工伤保险；</text>
      <text>（13）伤残抚恤；</text>
      <text>（14）独生子女保健费；</text>
      <text>（15）遗属；</text>
      <text>（16）死亡抚恤；</text>
      <text>（17）精退人员；</text>
      <text>（18）离休工资；</text>
      <text>（19）离休干部生活补贴；</text>
      <text>（20）其他预算资金；</text>
      <a>点此查看每类资金的说明。</a>

      <p style="color: red"><b>不同分类的资金按照以下要求申报项目。</b></p>
      <table border="1" frame="void" cellspacing="0" cellpadding="10">
        <thead>
          <tr>
            <th>序号</th>
            <th>资金类别</th>
            <th>项目类别</th>
            <th>存续状态</th>
            <th>支出项目类别</th>
            <th>项目名称</th>
            <th>分年度计划-财政拨款额</th>
            <th>备注</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>01基本工资<br />02津贴补贴<br />03事业绩效工资</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001002-事业及工勤工资津补贴</td>
            <td>XXXX年工资津补贴</td>
            <td>01、02、03三项年总额</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>04基础绩效奖金</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001006-奖金</td>
            <td>XXXX年基础绩效奖金</td>
            <td>04项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>05住房补贴</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001005-住房公积金</td>
            <td>XXXX年住房公积金</td>
            <td>05项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>06取暖费<br />07降温费</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001009-个人采暖补贴</td>
            <td>XXXX年采暖降温费</td>
            <td>06、07两项总额度</td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>08养老保险</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001007-基本养老保险缴费</td>
            <td>XXXX年养老保险费</td>
            <td>08项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td>09医疗保险<br />10大病保险<br />11生育保险</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001008-职工基本医疗保险缴费</td>
            <td>XXXX年医疗大病生育保险费</td>
            <td>09、10、11三项总额度</td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td>12工伤保险</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001003-其他工资福利支出</td>
            <td>XXXX年工伤保险费</td>
            <td>12项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>13伤残抚恤</td>
            <td>12-对个人和家庭补助支出</td>
            <td>一次性项目</td>
            <td>001002001-对个人和家庭补助支出（在职）</td>
            <td>XXXX年伤残抚恤</td>
            <td>13项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>14独生子女保健费</td>
            <td>11-工资福利支出</td>
            <td>一次性项目</td>
            <td>001001006-奖金</td>
            <td>XXXX年独生子女保健费</td>
            <td>14项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>15遗属</td>
            <td>12-对个人和家庭补助支出</td>
            <td>一次性项目</td>
            <td>001002001-对个人和家庭补助支出（在职）</td>
            <td>XXXX年遗属</td>
            <td>15项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>16死亡抚恤</td>
            <td>12-对个人和家庭补助支出</td>
            <td>一次性项目</td>
            <td>001002001-对个人和家庭补助支出（在职）</td>
            <td>XXXX年死亡抚恤</td>
            <td>16项额度</td>
            <td>年初预算一般不涉及此项，除非最近一次追加预算后有人去世。</td>
          </tr>
          <tr>
            <td>12</td>
            <td>17精退人员</td>
            <td>12-对个人和家庭补助支出</td>
            <td>一次性项目</td>
            <td>001002001-对个人和家庭补助支出（在职）</td>
            <td>XXXX年精退人员</td>
            <td>17项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>18离休工资</td>
            <td>12-对个人和家庭补助支出</td>
            <td>一次性项目</td>
            <td>001002001-对个人和家庭补助支出（在职）</td>
            <td>XXXX年离休费</td>
            <td>18项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>19离休干部生活补贴</td>
            <td>12-对个人和家庭补助支出</td>
            <td>一次性项目</td>
            <td>001002001-对个人和家庭补助支出（在职）</td>
            <td>XXXX年离休补贴</td>
            <td>19项额度</td>
            <td></td>
          </tr>
          <tr>
            <td>15</td>
            <td>20其他预算资金</td>
            <td>参照前19项资金选择项目类别</td>
            <td>一次性项目</td>
            <td>参照前19项资金选择支出项目类别</td>
            <td>根据资金用途取合适的项目名称</td>
            <td>预算的额度</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="min-width: 50px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image_add: require("@/assets/yujuesaun/预算_增加项目_新增.png"),
      image_baseinfo: require("@/assets/yujuesaun/预算_增加项目_项目基本信息.png"),
      image_yearplan: require("@/assets/yujuesaun/预算_增加项目_分年度支出计划.png"),
      image_extrainfo: require("@/assets/yujuesaun/预算_增加项目_附件信息.png"),
      image_hot: require("@/assets/yujuesaun/预算_增加项目_热点.png"),
      image_send: require("@/assets/yujuesaun/预算_增加项目_送审.png"),
    };
  },
  methods: {
    scrollTo(id) {
      this.$refs[id].scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.btn {
  border: none;
  border-radius: 0px !important;
  width: 100%;
  margin-left: 0px !important;
}

.btn.bt {
  border-top: 1px solid #aaa;
}

.btn.bb {
  border-bottom: 1px solid #aaa;
}

h1 {
  text-align: center;
}

p {
  margin-top: 20px;
  text-indent: 2em;
  font-size: 16px;
  line-height: 30px;
}

img {
  border: 1px solid #666;
}

th,
td {
  padding: 10px;
}
</style>